import React from 'react'
import { graphql } from 'gatsby'
import { get } from 'lodash/fp'

// Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Blocks } from 'components/Blocks'

// Types
import { ContentfulPage } from 'types/contentful'

type Props = {
  data: {
    page: ContentfulPage
  }
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const { page } = data
  const seoTitle = page.seoTitle ? page.seoTitle : page.title
  const theme = page.theme ? page.theme : 'Dark'

  return (
    <Layout theme={theme}>
      <SEO title={seoTitle} description={get('excerpt.excerpt', page)} />
      <Blocks blocks={page.blocks} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexPage {
    page: contentfulPage(slug: { eq: "home" }) {
      title
      seoTitle
      theme
      excerpt {
        excerpt
      }
      blocks {
        ... on ContentfulBlock {
          ...BlocksFragment
        }
        ... on ContentfulBlockLatestBlogPosts {
          __typename
          id
          title
          category
        }
      }
    }
  }
`
